import Link from "next/link";
import Image from "next/future/image";
import { getProductLink, downloadLink } from "@/lib/utils.js";
import { CartContext } from "@/lib/cart_context.js";
import { useContext } from "react";
import { targetIcon } from "@/lib/icons";
export default function ProductTile({
  attributesApplied,
  product,
  addCompareProduct,
  prodRemove,
  canCompare,
  isCompared,
  compareProducts,
  hotSpotDisplay
}) {
  const productLink = getProductLink(product);
  const cart = useContext(CartContext);
  const handleChange = (event) => {
    if (event.target.checked) {
      addCompareProduct();
    } else {
      prodRemove();
    }
  };

  const contain = { objectFit: "contain" };
  const itemInCart = cart?.itemInCart && cart.itemInCart(product.id);
  const hasAttributes = product.attributes?.length > 0 ;
  return (
    <div className={`lexcms-product-tile ${attributesApplied ? 'lexcms-product-tile-with-attributes' : ''}`}>
      <div className={`lexcms-product-tile-image-area ${attributesApplied ? 'lexcms-product-tile-with-attributes-image-area' : ''} ${product.secondary_image ? '--lexcms-has-secondary-image' : '' }`}>
        <Link href={productLink}>
          <a>
            <Image
              unoptimized
              priority
              src={product.image}
              alt={product.name + " product page"}
              className="lexcms-product-tile-image"
              fill
              style={contain}
            />
            {product.secondary_image &&
            <>
            <Image
              unoptimized
              priority
              src={product.secondary_image.url}
              alt={""}
              className="lexcms-product-tile-swap-image"
              fill
              style={contain}
            />
            </>

            }
            { hotSpotDisplay && 
              <div className='marker-container'>
                <div className='marker'>
                  { targetIcon }
                </div>
              </div>
            }
          </a>
        </Link>
        {((canCompare && compareProducts.length < 4) ||
          (isCompared && canCompare)) && (
          <label className="lexcms-product-tile-compare-checkbox-container">
            <input
              type="checkbox"
              onChange={handleChange}
              checked={isCompared}
              className="lexcms-product-tile-compare-checkbox"
            />
            <span className="lexcms-product-tile-compare-checkbox-label">
              Compare
            </span>
          </label>
        )}
        {cart?.itemInCart && (
          <label className="lexcms-product-tile-compare-checkbox-container lexcms-tile-cart-add">
            <input
              type="checkbox"
              onChange={() =>
                itemInCart
                  ? cart.removeItem(product.id)
                  : cart.addItem(product.id)
              }
              checked={itemInCart ? true : false}
              className="lexcms-product-tile-compare-checkbox"
            />
            <span className="lexcms-product-tile-compare-checkbox-label ">
              {itemInCart ? "Remove" : "Save"}
            </span>
          </label>
        )}
      </div>
      <div className="lexcms-product-tile-text-area">
        {product.is_new && (
          <Link href={productLink}>
            <div className="lexcms-product-tile-new-product">New Product</div>
          </Link>
        )}
        <Link href={productLink}>
          <a>
            <p className="lexcms-product-tile-name">{product.name}</p>
          </a>
        </Link>
        <div className="lexcms-product-tile-subname-container">
         {product.sub_name &&
          <Link href={productLink}>
              <p className="lexcms-product-tile-subname">{product.sub_name}</p>
          </Link>
          }
        </div>
        {hasAttributes &&
        <div className="lexcms-product-tile-attribute-container">
          {product.attributes.map((attr, i) => {
            return (
          <p key={i} className="lexcms-product-tile-attribute-value">
            <strong>{attr.name }: </strong>
            {attr.values} 
          </p>)
          })
          }
          
          
        </div>
        }
        {product.primary_spec_sheet && product.primary_spec_sheet.url && (
          <a
            href={downloadLink(product.primary_spec_sheet)}
            target="_blank"
            className="lexcms-regular-button lexcms-product-tile-spec-sheet-button"
          >
            Specification Sheet
          </a>
        )}
      </div>
    </div>
  );
}
